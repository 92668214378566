export const ADMIN_TEXTDOCUMENT_LIST_REQUEST = 'ADMIN_TEXTDOCUMENT_LIST_REQUEST';
export const ADMIN_TEXTDOCUMENT_LIST_SUCCESS = 'ADMIN_TEXTDOCUMENT_LIST_SUCCESS';
export const ADMIN_TEXTDOCUMENT_LIST_FAIL = 'ADMIN_TEXTDOCUMENT_LIST_FAIL';

export const ADMIN_TEXTDOCUMENT_DETAILS_REQUEST = 'ADMIN_TEXTDOCUMENT_DETAILS_REQUEST';
export const ADMIN_TEXTDOCUMENT_DETAILS_SUCCESS = 'ADMIN_TEXTDOCUMENT_DETAILS_SUCCESS';
export const ADMIN_TEXTDOCUMENT_DETAILS_FAIL = 'ADMIN_TEXTDOCUMENT_DETAILS_FAIL';

export const ADMIN_TEXTDOCUMENT_CREATE_REQUEST = 'ADMIN_TEXTDOCUMENT_CREATE_REQUEST';
export const ADMIN_TEXTDOCUMENT_CREATE_SUCCESS = 'ADMIN_TEXTDOCUMENT_CREATE_SUCCESS';
export const ADMIN_TEXTDOCUMENT_CREATE_FAIL = 'ADMIN_TEXTDOCUMENT_CREATE_FAIL';

export const ADMIN_TEXTDOCUMENT_UPDATE_REQUEST = 'ADMIN_TEXTDOCUMENT_UPDATE_REQUEST';
export const ADMIN_TEXTDOCUMENT_UPDATE_SUCCESS = 'ADMIN_TEXTDOCUMENT_UPDATE_SUCCESS';
export const ADMIN_TEXTDOCUMENT_UPDATE_FAIL = 'ADMIN_TEXTDOCUMENT_UPDATE_FAIL';
export const ADMIN_TEXTDOCUMENT_UPDATE_RESET = 'ADMIN_TEXTDOCUMENT_UPDATE_RESET';

export const ADMIN_TEXTDOCUMENT_DELETE_REQUEST = 'ADMIN_TEXTDOCUMENT_DELETE_REQUEST';
export const ADMIN_TEXTDOCUMENT_DELETE_SUCCESS = 'ADMIN_TEXTDOCUMENT_DELETE_SUCCESS';
export const ADMIN_TEXTDOCUMENT_DELETE_FAIL = 'ADMIN_TEXTDOCUMENT_DELETE_FAIL';
