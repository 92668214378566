export const ADMIN_USER_LIST_REQUEST = 'ADMIN_USER_LIST_REQUEST';
export const ADMIN_USER_LIST_SUCCESS = 'ADMIN_USER_LIST_SUCCESS';
export const ADMIN_USER_LIST_FAIL = 'ADMIN_USER_LIST_FAIL';

export const ADMIN_USER_DETAILS_REQUEST = 'ADMIN_USER_DETAILS_REQUEST';
export const ADMIN_USER_DETAILS_SUCCESS = 'ADMIN_USER_DETAILS_SUCCESS';
export const ADMIN_USER_DETAILS_FAIL = 'ADMIN_USER_DETAILS_FAIL';

export const ADMIN_USER_CREATE_REQUEST = 'ADMIN_USER_CREATE_REQUEST';
export const ADMIN_USER_CREATE_SUCCESS = 'ADMIN_USER_CREATE_SUCCESS';
export const ADMIN_USER_CREATE_FAIL = 'ADMIN_USER_CREATE_FAIL';
export const ADMIN_USER_CREATE_RESET = 'ADMIN_USER_CREATE_RESET';

export const ADMIN_USER_UPDATE_REQUEST = 'ADMIN_USER_UPDATE_REQUEST';
export const ADMIN_USER_UPDATE_SUCCESS = 'ADMIN_USER_UPDATE_SUCCESS';
export const ADMIN_USER_UPDATE_FAIL = 'ADMIN_USER_UPDATE_FAIL';
export const ADMIN_USER_UPDATE_RESET = 'ADMIN_USER_UPDATE_RESET';

export const ADMIN_USER_DELETE_REQUEST = 'ADMIN_USER_DELETE_REQUEST';
export const ADMIN_USER_DELETE_SUCCESS = 'ADMIN_USER_DELETE_SUCCESS';
export const ADMIN_USER_DELETE_FAIL = 'ADMIN_USER_DELETE_FAIL';

export const ADMIN_ADMIN_USER_LIST_REQUEST = 'ADMIN_ADMIN_USER_LIST_REQUEST';
export const ADMIN_ADMIN_USER_LIST_SUCCESS = 'ADMIN_ADMIN_USER_LIST_SUCCESS';
export const ADMIN_ADMIN_USER_LIST_FAIL = 'ADMIN_ADMIN_USER_LIST_FAIL';
