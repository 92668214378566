import axiosInstance from '../axiosConfig';
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_CHANGE_PASSWORD_REQUEST,
    USER_CHANGE_PASSWORD_SUCCESS,
    USER_CHANGE_PASSWORD_FAIL,
} from '../constants/userConstants';

// Helper function for error message
const getErrorMessage = (error) => {
    return error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message;
};

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const payload = { email, password };

        const { data } = await axiosInstance.post('/api/users/login/', payload, config);

        dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: getErrorMessage(error),
        });
    }
};

export const register = (name, email, password, streetAddress, locality, city, state, pinCode, phoneNumber) => async (dispatch) => {
    try {
        dispatch({ type: USER_REGISTER_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const [first_name, ...last_name_parts] = name.split(' ');
        const last_name = last_name_parts.join(' ');

        const payload = {
            username: email,
            email,
            password,
            first_name,
            last_name,
            street_address: streetAddress,
            locality,
            city,
            state,
            pin_code: pinCode,
            country: 'India',
            phone_number: phoneNumber,
        };

        const { data } = await axiosInstance.post('/api/users/register/', payload, config);

        dispatch({ type: USER_REGISTER_SUCCESS, payload: data });

        dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: getErrorMessage(error),
        });
    }
};

export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo');
    dispatch({ type: USER_LOGOUT });
    // Use react-router history push or navigate here instead of a hard refresh
    window.location.replace('/#/login');
};

export const getUserProfile = () => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_PROFILE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axiosInstance.get('/api/users/profile/', config);

        dispatch({ type: USER_PROFILE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_PROFILE_FAIL,
            payload: getErrorMessage(error),
        });
    }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_UPDATE_PROFILE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axiosInstance.put('/api/users/profile/update/', user, config);

        dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });

        // Update local storage and state with the new token if provided
        const updatedUserInfo = data.token
            ? { ...userInfo, ...data }
            : {
                ...data,
                token: (await axiosInstance.post('/api/token/refresh/', { refresh: userInfo.refresh })).data.access,
                refresh: userInfo.refresh,
            };

        dispatch({ type: USER_LOGIN_SUCCESS, payload: updatedUserInfo });
        localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
    } catch (error) {
        dispatch({
            type: USER_UPDATE_PROFILE_FAIL,
            payload: getErrorMessage(error),
        });
    }
};

export const changePassword = (currentPassword, newPassword, confirmPassword) => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_CHANGE_PASSWORD_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axiosInstance.put('/api/users/change-password/', { currentPassword, newPassword, confirmPassword }, config);

        dispatch({ type: USER_CHANGE_PASSWORD_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_CHANGE_PASSWORD_FAIL,
            payload: getErrorMessage(error),
        });
    }
};
