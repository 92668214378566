export const ADMIN_QUESTION_LIST_REQUEST = 'ADMIN_QUESTION_LIST_REQUEST';
export const ADMIN_QUESTION_LIST_SUCCESS = 'ADMIN_QUESTION_LIST_SUCCESS';
export const ADMIN_QUESTION_LIST_FAIL = 'ADMIN_QUESTION_LIST_FAIL';

export const ADMIN_QUESTION_DETAILS_REQUEST = 'ADMIN_QUESTION_DETAILS_REQUEST';
export const ADMIN_QUESTION_DETAILS_SUCCESS = 'ADMIN_QUESTION_DETAILS_SUCCESS';
export const ADMIN_QUESTION_DETAILS_FAIL = 'ADMIN_QUESTION_DETAILS_FAIL';

export const ADMIN_QUESTION_CREATE_REQUEST = 'ADMIN_QUESTION_CREATE_REQUEST';
export const ADMIN_QUESTION_CREATE_SUCCESS = 'ADMIN_QUESTION_CREATE_SUCCESS';
export const ADMIN_QUESTION_CREATE_FAIL = 'ADMIN_QUESTION_CREATE_FAIL';
export const ADMIN_QUESTION_CREATE_RESET = 'ADMIN_QUESTION_CREATE_RESET';

export const ADMIN_QUESTION_UPDATE_REQUEST = 'ADMIN_QUESTION_UPDATE_REQUEST';
export const ADMIN_QUESTION_UPDATE_SUCCESS = 'ADMIN_QUESTION_UPDATE_SUCCESS';
export const ADMIN_QUESTION_UPDATE_FAIL = 'ADMIN_QUESTION_UPDATE_FAIL';
export const ADMIN_QUESTION_UPDATE_RESET = 'ADMIN_QUESTION_UPDATE_RESET';

export const ADMIN_QUESTION_DELETE_REQUEST = 'ADMIN_QUESTION_DELETE_REQUEST';
export const ADMIN_QUESTION_DELETE_SUCCESS = 'ADMIN_QUESTION_DELETE_SUCCESS';
export const ADMIN_QUESTION_DELETE_FAIL = 'ADMIN_QUESTION_DELETE_FAIL';
