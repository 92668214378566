export const ADMIN_SUBCHAPTER_LIST_REQUEST = 'ADMIN_SUBCHAPTER_LIST_REQUEST';
export const ADMIN_SUBCHAPTER_LIST_SUCCESS = 'ADMIN_SUBCHAPTER_LIST_SUCCESS';
export const ADMIN_SUBCHAPTER_LIST_FAIL = 'ADMIN_SUBCHAPTER_LIST_FAIL';

export const ADMIN_SUBCHAPTER_DETAILS_REQUEST = 'ADMIN_SUBCHAPTER_DETAILS_REQUEST';
export const ADMIN_SUBCHAPTER_DETAILS_SUCCESS = 'ADMIN_SUBCHAPTER_DETAILS_SUCCESS';
export const ADMIN_SUBCHAPTER_DETAILS_FAIL = 'ADMIN_SUBCHAPTER_DETAILS_FAIL';

export const ADMIN_SUBCHAPTER_CREATE_REQUEST = 'ADMIN_SUBCHAPTER_CREATE_REQUEST';
export const ADMIN_SUBCHAPTER_CREATE_SUCCESS = 'ADMIN_SUBCHAPTER_CREATE_SUCCESS';
export const ADMIN_SUBCHAPTER_CREATE_FAIL = 'ADMIN_SUBCHAPTER_CREATE_FAIL';

export const ADMIN_SUBCHAPTER_UPDATE_REQUEST = 'ADMIN_SUBCHAPTER_UPDATE_REQUEST';
export const ADMIN_SUBCHAPTER_UPDATE_SUCCESS = 'ADMIN_SUBCHAPTER_UPDATE_SUCCESS';
export const ADMIN_SUBCHAPTER_UPDATE_FAIL = 'ADMIN_SUBCHAPTER_UPDATE_FAIL';
export const ADMIN_SUBCHAPTER_UPDATE_RESET = 'ADMIN_SUBCHAPTER_UPDATE_RESET';

export const ADMIN_SUBCHAPTER_DELETE_REQUEST = 'ADMIN_SUBCHAPTER_DELETE_REQUEST';
export const ADMIN_SUBCHAPTER_DELETE_SUCCESS = 'ADMIN_SUBCHAPTER_DELETE_SUCCESS';
export const ADMIN_SUBCHAPTER_DELETE_FAIL = 'ADMIN_SUBCHAPTER_DELETE_FAIL';
