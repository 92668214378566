// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ adminOnly = false, children }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo) {
    return <Navigate to="/login" replace />;
  }

  if (adminOnly && !userInfo.isAdmin) {
    return <Navigate to="/profile" replace />;
  }

  return children ? children : <Outlet />;
};

export const AdminProtectedRoute = ({ element }) => (
  <ProtectedRoute adminOnly>{element}</ProtectedRoute>
);

export default ProtectedRoute;
