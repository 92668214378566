import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { combineReducers } from 'redux'; // Import combineReducers from redux
import {
  courseListReducer,
  courseDetailsReducer,
} from './reducers/courseReducers';
import chapterListReducer from './reducers/chapterReducers';
import subtopicListReducer from './reducers/subtopicReducers';
import {
  reviewListReducer,
  reviewCreateReducer,
} from './reducers/reviewReducers';
import {
  userLoginReducer,
  userRegisterReducer,
  userProfileReducer,
  userUpdateProfileReducer,
  userChangePasswordReducer,
} from './reducers/userReducers';
import { instructorDetailsReducer } from './reducers/instructorReducers';
import { textDocumentListReducer } from './reducers/textDocumentReducers';
import {
  questionListReducer,
  questionPostReducer,
  answerPostReducer,
} from './reducers/questionReducers';
import {
  subscriptionReducer,
  checkSubscriptionReducer,
  subscriptionDetailsReducer,
  extendSubscriptionReducer,
  createRazorpayOrderReducer,
  verifyPaymentReducer,
} from './reducers/subscriptionReducers';
import {
  adminUserListReducer,
  adminUserDetailsReducer,
  adminUserUpdateReducer,
  adminUserDeleteReducer,
  adminAdminUserListReducer,
  adminUserCreateReducer,
} from './reducers/adminUserReducers';
import {
  adminCourseListReducer,
  adminCourseDetailsReducer,
  adminCourseCreateReducer,
  adminCourseUpdateReducer,
  adminCourseDeleteReducer,
} from './reducers/adminCourseReducers';
import {
  adminChapterListReducer,
  adminChapterDetailsReducer,
  adminChapterCreateReducer,
  adminChapterUpdateReducer,
  adminChapterDeleteReducer,
} from './reducers/adminChapterReducers';
import {
  adminSubchapterListReducer,
  adminSubchapterDetailsReducer,
  adminSubchapterCreateReducer,
  adminSubchapterUpdateReducer,
  adminSubchapterDeleteReducer,
} from './reducers/adminSubchapterReducers';
import {
  adminSubtopicListReducer,
  adminSubtopicDetailsReducer,
  adminSubtopicCreateReducer,
  adminSubtopicUpdateReducer,
  adminSubtopicDeleteReducer,
} from './reducers/adminSubtopicReducers';
import {
  adminTextDocumentListReducer,
  adminTextDocumentDetailsReducer,
  adminTextDocumentCreateReducer,
  adminTextDocumentUpdateReducer,
  adminTextDocumentDeleteReducer,
} from './reducers/adminTextDocumentReducers';
import {
  adminReviewListReducer,
  adminReviewDeleteReducer,
} from './reducers/adminReviewReducers';
import {
  adminInstructorListReducer,
  adminInstructorDetailsReducer,
  adminInstructorCreateReducer,
  adminInstructorUpdateReducer,
  adminInstructorDeleteReducer,
} from './reducers/adminInstructorReducers';
import {
  adminQuestionListReducer,
  adminQuestionDetailsReducer,
  adminQuestionCreateReducer,
  adminQuestionUpdateReducer,
  adminQuestionDeleteReducer,
} from './reducers/adminQuestionReducers';
import {
  adminSubscriptionListReducer,
  adminSubscriptionDetailsReducer,
  adminSubscriptionCreateReducer,
  adminSubscriptionUpdateReducer,
  adminSubscriptionDeleteReducer,
} from './reducers/adminSubscriptionReducers';

// Redux Persist Configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['checkSubscription'], // Add reducers you want to persist here
};

// Root reducer
const rootReducer = combineReducers({
  courseList: courseListReducer,
  courseDetails: courseDetailsReducer,
  chapterList: chapterListReducer,
  subtopicList: subtopicListReducer,
  reviewList: reviewListReducer,
  reviewCreate: reviewCreateReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userProfile: userProfileReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userChangePassword: userChangePasswordReducer,
  instructorDetails: instructorDetailsReducer,
  textDocumentList: textDocumentListReducer,
  questionList: questionListReducer,
  questionPost: questionPostReducer,
  answerPost: answerPostReducer,
  subscription: subscriptionReducer,
  checkSubscription: checkSubscriptionReducer,
  subscriptionDetails: subscriptionDetailsReducer,
  extendSubscription: extendSubscriptionReducer,
  createRazorpayOrder: createRazorpayOrderReducer,
  verifyPayment: verifyPaymentReducer,
  adminUserList: adminUserListReducer,
  adminUserCreate: adminUserCreateReducer,
  adminUserDetails: adminUserDetailsReducer,
  adminUserUpdate: adminUserUpdateReducer,
  adminAdminUserList: adminAdminUserListReducer,
  adminUserDelete: adminUserDeleteReducer,
  adminCourseList: adminCourseListReducer,
  adminCourseDetails: adminCourseDetailsReducer,
  adminCourseCreate: adminCourseCreateReducer,
  adminCourseUpdate: adminCourseUpdateReducer,
  adminCourseDelete: adminCourseDeleteReducer,
  adminChapterList: adminChapterListReducer,
  adminChapterDetails: adminChapterDetailsReducer,
  adminChapterCreate: adminChapterCreateReducer,
  adminChapterUpdate: adminChapterUpdateReducer,
  adminChapterDelete: adminChapterDeleteReducer,
  adminSubchapterList: adminSubchapterListReducer,
  adminSubchapterDetails: adminSubchapterDetailsReducer,
  adminSubchapterCreate: adminSubchapterCreateReducer,
  adminSubchapterUpdate: adminSubchapterUpdateReducer,
  adminSubchapterDelete: adminSubchapterDeleteReducer,
  adminSubtopicList: adminSubtopicListReducer,
  adminSubtopicDetails: adminSubtopicDetailsReducer,
  adminSubtopicCreate: adminSubtopicCreateReducer,
  adminSubtopicUpdate: adminSubtopicUpdateReducer,
  adminSubtopicDelete: adminSubtopicDeleteReducer,
  adminTextDocumentList: adminTextDocumentListReducer,
  adminTextDocumentDetails: adminTextDocumentDetailsReducer,
  adminTextDocumentCreate: adminTextDocumentCreateReducer,
  adminTextDocumentUpdate: adminTextDocumentUpdateReducer,
  adminTextDocumentDelete: adminTextDocumentDeleteReducer,
  adminReviewList: adminReviewListReducer,
  adminReviewDelete: adminReviewDeleteReducer,
  adminInstructorList: adminInstructorListReducer,
  adminInstructorDetails: adminInstructorDetailsReducer,
  adminInstructorCreate: adminInstructorCreateReducer,
  adminInstructorUpdate: adminInstructorUpdateReducer,
  adminInstructorDelete: adminInstructorDeleteReducer,
  adminQuestionList: adminQuestionListReducer,
  adminQuestionDetails: adminQuestionDetailsReducer,
  adminQuestionCreate: adminQuestionCreateReducer,
  adminQuestionUpdate: adminQuestionUpdateReducer,
  adminQuestionDelete: adminQuestionDeleteReducer,
  adminSubscriptionList: adminSubscriptionListReducer,
  adminSubscriptionDetails: adminSubscriptionDetailsReducer,
  adminSubscriptionCreate: adminSubscriptionCreateReducer,
  adminSubscriptionUpdate: adminSubscriptionUpdateReducer,
  adminSubscriptionDelete: adminSubscriptionDeleteReducer,
});

// Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Fetch userInfo from local storage to initialize state if present
const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

// Set initial state with user info
const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

// Configure Redux store with reducers, middleware, and preloaded state
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk,
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk),
  preloadedState: initialState,
});

const persistor = persistStore(store);

export { store, persistor };
