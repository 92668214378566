// src/constants/adminInstructorConstants.js
export const ADMIN_INSTRUCTOR_LIST_REQUEST = 'ADMIN_INSTRUCTOR_LIST_REQUEST';
export const ADMIN_INSTRUCTOR_LIST_SUCCESS = 'ADMIN_INSTRUCTOR_LIST_SUCCESS';
export const ADMIN_INSTRUCTOR_LIST_FAIL = 'ADMIN_INSTRUCTOR_LIST_FAIL';

export const ADMIN_INSTRUCTOR_DETAILS_REQUEST = 'ADMIN_INSTRUCTOR_DETAILS_REQUEST';
export const ADMIN_INSTRUCTOR_DETAILS_SUCCESS = 'ADMIN_INSTRUCTOR_DETAILS_SUCCESS';
export const ADMIN_INSTRUCTOR_DETAILS_FAIL = 'ADMIN_INSTRUCTOR_DETAILS_FAIL';

export const ADMIN_INSTRUCTOR_CREATE_REQUEST = 'ADMIN_INSTRUCTOR_CREATE_REQUEST';
export const ADMIN_INSTRUCTOR_CREATE_SUCCESS = 'ADMIN_INSTRUCTOR_CREATE_SUCCESS';
export const ADMIN_INSTRUCTOR_CREATE_FAIL = 'ADMIN_INSTRUCTOR_CREATE_FAIL';

export const ADMIN_INSTRUCTOR_UPDATE_REQUEST = 'ADMIN_INSTRUCTOR_UPDATE_REQUEST';
export const ADMIN_INSTRUCTOR_UPDATE_SUCCESS = 'ADMIN_INSTRUCTOR_UPDATE_SUCCESS';
export const ADMIN_INSTRUCTOR_UPDATE_FAIL = 'ADMIN_INSTRUCTOR_UPDATE_FAIL';
export const ADMIN_INSTRUCTOR_UPDATE_RESET = 'ADMIN_INSTRUCTOR_UPDATE_RESET';

export const ADMIN_INSTRUCTOR_DELETE_REQUEST = 'ADMIN_INSTRUCTOR_DELETE_REQUEST';
export const ADMIN_INSTRUCTOR_DELETE_SUCCESS = 'ADMIN_INSTRUCTOR_DELETE_SUCCESS';
export const ADMIN_INSTRUCTOR_DELETE_FAIL = 'ADMIN_INSTRUCTOR_DELETE_FAIL';
