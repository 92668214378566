import {
  ADMIN_INSTRUCTOR_LIST_REQUEST,
  ADMIN_INSTRUCTOR_LIST_SUCCESS,
  ADMIN_INSTRUCTOR_LIST_FAIL,
  ADMIN_INSTRUCTOR_DETAILS_REQUEST,
  ADMIN_INSTRUCTOR_DETAILS_SUCCESS,
  ADMIN_INSTRUCTOR_DETAILS_FAIL,
  ADMIN_INSTRUCTOR_CREATE_REQUEST,
  ADMIN_INSTRUCTOR_CREATE_SUCCESS,
  ADMIN_INSTRUCTOR_CREATE_FAIL,
  ADMIN_INSTRUCTOR_UPDATE_REQUEST,
  ADMIN_INSTRUCTOR_UPDATE_SUCCESS,
  ADMIN_INSTRUCTOR_UPDATE_FAIL,
  ADMIN_INSTRUCTOR_UPDATE_RESET,
  ADMIN_INSTRUCTOR_DELETE_REQUEST,
  ADMIN_INSTRUCTOR_DELETE_SUCCESS,
  ADMIN_INSTRUCTOR_DELETE_FAIL,
} from '../constants/adminInstructorConstants';

export const adminInstructorListReducer = (state = { instructors: [] }, action) => {
  switch (action.type) {
    case ADMIN_INSTRUCTOR_LIST_REQUEST:
      return { loading: true, instructors: [] };
    case ADMIN_INSTRUCTOR_LIST_SUCCESS:
      return { loading: false, instructors: action.payload };
    case ADMIN_INSTRUCTOR_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminInstructorDetailsReducer = (state = { instructor: {} }, action) => {
  switch (action.type) {
    case ADMIN_INSTRUCTOR_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_INSTRUCTOR_DETAILS_SUCCESS:
      return { loading: false, instructor: action.payload };
    case ADMIN_INSTRUCTOR_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminInstructorCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_INSTRUCTOR_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_INSTRUCTOR_CREATE_SUCCESS:
      return { loading: false, success: true, instructor: action.payload };
    case ADMIN_INSTRUCTOR_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminInstructorUpdateReducer = (state = { instructor: {} }, action) => {
  switch (action.type) {
    case ADMIN_INSTRUCTOR_UPDATE_REQUEST:
      return { loading: true };
    case ADMIN_INSTRUCTOR_UPDATE_SUCCESS:
      return { loading: false, success: true, instructor: action.payload };
    case ADMIN_INSTRUCTOR_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_INSTRUCTOR_UPDATE_RESET:
      return { instructor: {} };
    default:
      return state;
  }
};

export const adminInstructorDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_INSTRUCTOR_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_INSTRUCTOR_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_INSTRUCTOR_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
