// src/reducers/instructorDetailsReducer.js
import {
  INSTRUCTOR_DETAILS_REQUEST,
  INSTRUCTOR_DETAILS_SUCCESS,
  INSTRUCTOR_DETAILS_FAIL,
} from '../constants/instructorConstants';

const initialInstructorState = { instructor: {} };

export const instructorDetailsReducer = (state = initialInstructorState, action) => {
  switch (action.type) {
    case INSTRUCTOR_DETAILS_REQUEST:
      return { loading: true, ...state };
    case INSTRUCTOR_DETAILS_SUCCESS:
      return { loading: false, instructor: action.payload };
    case INSTRUCTOR_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
