import {
  ADMIN_SUBSCRIPTION_LIST_REQUEST,
  ADMIN_SUBSCRIPTION_LIST_SUCCESS,
  ADMIN_SUBSCRIPTION_LIST_FAIL,
  ADMIN_SUBSCRIPTION_DETAILS_REQUEST,
  ADMIN_SUBSCRIPTION_DETAILS_SUCCESS,
  ADMIN_SUBSCRIPTION_DETAILS_FAIL,
  ADMIN_SUBSCRIPTION_CREATE_REQUEST,
  ADMIN_SUBSCRIPTION_CREATE_SUCCESS,
  ADMIN_SUBSCRIPTION_CREATE_FAIL,
  ADMIN_SUBSCRIPTION_UPDATE_REQUEST,
  ADMIN_SUBSCRIPTION_UPDATE_SUCCESS,
  ADMIN_SUBSCRIPTION_UPDATE_FAIL,
  ADMIN_SUBSCRIPTION_DELETE_REQUEST,
  ADMIN_SUBSCRIPTION_DELETE_SUCCESS,
  ADMIN_SUBSCRIPTION_DELETE_FAIL,
} from '../constants/adminSubscriptionConstants';

export const adminSubscriptionListReducer = (state = { subscriptions: [] }, action) => {
  switch (action.type) {
    case ADMIN_SUBSCRIPTION_LIST_REQUEST:
      return { loading: true, subscriptions: [] };
    case ADMIN_SUBSCRIPTION_LIST_SUCCESS:
      return { loading: false, subscriptions: action.payload };
    case ADMIN_SUBSCRIPTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminSubscriptionDetailsReducer = (state = { subscription: {} }, action) => {
  switch (action.type) {
    case ADMIN_SUBSCRIPTION_DETAILS_REQUEST:
      return { loading: true, ...state };
    case ADMIN_SUBSCRIPTION_DETAILS_SUCCESS:
      return { loading: false, subscription: action.payload };
    case ADMIN_SUBSCRIPTION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminSubscriptionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_SUBSCRIPTION_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_SUBSCRIPTION_CREATE_SUCCESS:
      return { loading: false, success: true, subscription: action.payload };
    case ADMIN_SUBSCRIPTION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminSubscriptionUpdateReducer = (state = { subscription: {} }, action) => {
  switch (action.type) {
    case ADMIN_SUBSCRIPTION_UPDATE_REQUEST:
      return { loading: true };
    case ADMIN_SUBSCRIPTION_UPDATE_SUCCESS:
      return { loading: false, success: true, subscription: action.payload };
    case ADMIN_SUBSCRIPTION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminSubscriptionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_SUBSCRIPTION_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_SUBSCRIPTION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_SUBSCRIPTION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
