// src/reducers/chapterReducers.js

import {
  CHAPTER_LIST_REQUEST,
  CHAPTER_LIST_SUCCESS,
  CHAPTER_LIST_FAIL
} from '../constants/chapterConstants';

// Define an initial state to streamline default values
const initialState = {
  chapters: [],
  loading: false,
  error: null,
};

// Chapter List Reducer
const chapterListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAPTER_LIST_REQUEST:
      // When requesting chapters, set loading to true and reset error
      return { ...state, loading: true, error: null };

    case CHAPTER_LIST_SUCCESS:
      // When chapters are successfully fetched, update the state
      return { ...state, loading: false, chapters: action.payload };

    case CHAPTER_LIST_FAIL:
      // If fetching fails, update the error state
      return { ...state, loading: false, error: action.payload };

    default:
      // Return the current state if no actions are matched
      return state;
  }
};

export default chapterListReducer;
