import {
  ADMIN_CHAPTER_LIST_REQUEST,
  ADMIN_CHAPTER_LIST_SUCCESS,
  ADMIN_CHAPTER_LIST_FAIL,
  ADMIN_CHAPTER_DETAILS_REQUEST,
  ADMIN_CHAPTER_DETAILS_SUCCESS,
  ADMIN_CHAPTER_DETAILS_FAIL,
  ADMIN_CHAPTER_CREATE_REQUEST,
  ADMIN_CHAPTER_CREATE_SUCCESS,
  ADMIN_CHAPTER_CREATE_FAIL,
  ADMIN_CHAPTER_UPDATE_REQUEST,
  ADMIN_CHAPTER_UPDATE_SUCCESS,
  ADMIN_CHAPTER_UPDATE_FAIL,
  ADMIN_CHAPTER_DELETE_REQUEST,
  ADMIN_CHAPTER_DELETE_SUCCESS,
  ADMIN_CHAPTER_DELETE_FAIL,
} from '../constants/adminChapterConstants';

export const adminChapterListReducer = (state = { chapters: [] }, action) => {
  switch (action.type) {
    case ADMIN_CHAPTER_LIST_REQUEST:
      return { loading: true, chapters: [] };
    case ADMIN_CHAPTER_LIST_SUCCESS:
      return { loading: false, chapters: action.payload };
    case ADMIN_CHAPTER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminChapterDetailsReducer = (state = { chapter: {} }, action) => {
  switch (action.type) {
    case ADMIN_CHAPTER_DETAILS_REQUEST:
      return { loading: true, ...state };
    case ADMIN_CHAPTER_DETAILS_SUCCESS:
      return { loading: false, chapter: action.payload };
    case ADMIN_CHAPTER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminChapterCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_CHAPTER_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_CHAPTER_CREATE_SUCCESS:
      return { loading: false, success: true, chapter: action.payload };
    case ADMIN_CHAPTER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminChapterUpdateReducer = (state = { chapter: {} }, action) => {
  switch (action.type) {
    case ADMIN_CHAPTER_UPDATE_REQUEST:
      return { loading: true };
    case ADMIN_CHAPTER_UPDATE_SUCCESS:
      return { loading: false, success: true, chapter: action.payload };
    case ADMIN_CHAPTER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminChapterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_CHAPTER_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_CHAPTER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_CHAPTER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
