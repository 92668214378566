// src/constants/adminSubtopicConstants.js
export const ADMIN_SUBTOPIC_LIST_REQUEST = 'ADMIN_SUBTOPIC_LIST_REQUEST';
export const ADMIN_SUBTOPIC_LIST_SUCCESS = 'ADMIN_SUBTOPIC_LIST_SUCCESS';
export const ADMIN_SUBTOPIC_LIST_FAIL = 'ADMIN_SUBTOPIC_LIST_FAIL';

export const ADMIN_SUBTOPIC_DETAILS_REQUEST = 'ADMIN_SUBTOPIC_DETAILS_REQUEST';
export const ADMIN_SUBTOPIC_DETAILS_SUCCESS = 'ADMIN_SUBTOPIC_DETAILS_SUCCESS';
export const ADMIN_SUBTOPIC_DETAILS_FAIL = 'ADMIN_SUBTOPIC_DETAILS_FAIL';

export const ADMIN_SUBTOPIC_CREATE_REQUEST = 'ADMIN_SUBTOPIC_CREATE_REQUEST';
export const ADMIN_SUBTOPIC_CREATE_SUCCESS = 'ADMIN_SUBTOPIC_CREATE_SUCCESS';
export const ADMIN_SUBTOPIC_CREATE_FAIL = 'ADMIN_SUBTOPIC_CREATE_FAIL';

export const ADMIN_SUBTOPIC_UPDATE_REQUEST = 'ADMIN_SUBTOPIC_UPDATE_REQUEST';
export const ADMIN_SUBTOPIC_UPDATE_SUCCESS = 'ADMIN_SUBTOPIC_UPDATE_SUCCESS';
export const ADMIN_SUBTOPIC_UPDATE_FAIL = 'ADMIN_SUBTOPIC_UPDATE_FAIL';
export const ADMIN_SUBTOPIC_UPDATE_RESET = 'ADMIN_SUBTOPIC_UPDATE_RESET';

export const ADMIN_SUBTOPIC_DELETE_REQUEST = 'ADMIN_SUBTOPIC_DELETE_REQUEST';
export const ADMIN_SUBTOPIC_DELETE_SUCCESS = 'ADMIN_SUBTOPIC_DELETE_SUCCESS';
export const ADMIN_SUBTOPIC_DELETE_FAIL = 'ADMIN_SUBTOPIC_DELETE_FAIL';
