import {
  ADMIN_SUBTOPIC_LIST_REQUEST,
  ADMIN_SUBTOPIC_LIST_SUCCESS,
  ADMIN_SUBTOPIC_LIST_FAIL,
  ADMIN_SUBTOPIC_DETAILS_REQUEST,
  ADMIN_SUBTOPIC_DETAILS_SUCCESS,
  ADMIN_SUBTOPIC_DETAILS_FAIL,
  ADMIN_SUBTOPIC_CREATE_REQUEST,
  ADMIN_SUBTOPIC_CREATE_SUCCESS,
  ADMIN_SUBTOPIC_CREATE_FAIL,
  ADMIN_SUBTOPIC_UPDATE_REQUEST,
  ADMIN_SUBTOPIC_UPDATE_SUCCESS,
  ADMIN_SUBTOPIC_UPDATE_FAIL,
  ADMIN_SUBTOPIC_DELETE_REQUEST,
  ADMIN_SUBTOPIC_DELETE_SUCCESS,
  ADMIN_SUBTOPIC_DELETE_FAIL,
} from '../constants/adminSubtopicConstants';

export const adminSubtopicListReducer = (state = { subtopics: [] }, action) => {
  switch (action.type) {
    case ADMIN_SUBTOPIC_LIST_REQUEST:
      return { loading: true, subtopics: [] };
    case ADMIN_SUBTOPIC_LIST_SUCCESS:
      return { loading: false, subtopics: action.payload };
    case ADMIN_SUBTOPIC_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminSubtopicDetailsReducer = (state = { subtopic: {} }, action) => {
  switch (action.type) {
    case ADMIN_SUBTOPIC_DETAILS_REQUEST:
      return { loading: true, ...state };
    case ADMIN_SUBTOPIC_DETAILS_SUCCESS:
      return { loading: false, subtopic: action.payload };
    case ADMIN_SUBTOPIC_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminSubtopicCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_SUBTOPIC_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_SUBTOPIC_CREATE_SUCCESS:
      return { loading: false, success: true, subtopic: action.payload };
    case ADMIN_SUBTOPIC_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminSubtopicUpdateReducer = (state = { subtopic: {} }, action) => {
  switch (action.type) {
    case ADMIN_SUBTOPIC_UPDATE_REQUEST:
      return { loading: true };
    case ADMIN_SUBTOPIC_UPDATE_SUCCESS:
      return { loading: false, success: true, subtopic: action.payload };
    case ADMIN_SUBTOPIC_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminSubtopicDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_SUBTOPIC_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_SUBTOPIC_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_SUBTOPIC_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
