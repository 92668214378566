// src/axiosConfig.js

import axios from 'axios';
import Cookies from 'js-cookie';
import { logout } from './actions/userActions';
import { store } from './store';  // Update this line to use named import

// Create an instance of axios with custom configuration
const axiosConfig = axios.create();

// Interceptor to add CSRF token and Authorization header before each request
axiosConfig.interceptors.request.use(
  (config) => {
    // Add CSRF token from cookies if available
    const csrfToken = Cookies.get('csrftoken');
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
    }

    // Add Authorization header with JWT token if the user is logged in
    const userInfo = store.getState().userLogin.userInfo;
    if (userInfo && userInfo.token) {
      config.headers['Authorization'] = `Bearer ${userInfo.token}`;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor to handle 401 errors and refresh JWT token
axiosConfig.interceptors.response.use(
  (response) => response,  // Return response directly if successful
  async (error) => {
    const originalRequest = error.config;
    const userInfo = store.getState().userLogin.userInfo;
    const refreshToken = userInfo ? userInfo.refresh : null;

    // Check for 401 error and if the request has not been retried yet
    if (error.response && error.response.status === 401 && refreshToken && !originalRequest._retry) {
      originalRequest._retry = true;  // Mark the request as retried
      try {
        // Attempt to refresh the access token
        const { data } = await axios.post('/api/token/refresh/', { refresh: refreshToken });

        // Update the user information in the Redux store
        store.dispatch({
          type: 'USER_LOGIN_SUCCESS',
          payload: {
            ...userInfo,
            token: data.access,
          },
        });

        // Update the user information in localStorage
        localStorage.setItem('userInfo', JSON.stringify({
          ...userInfo,
          token: data.access,
        }));

        // Update the Authorization header with the new token
        originalRequest.headers['Authorization'] = `Bearer ${data.access}`;
        
        // Retry the original request with the new token
        return axiosConfig(originalRequest);
      } catch (refreshError) {
        // If refreshing fails, logout the user
        store.dispatch(logout());
        return Promise.reject(refreshError);
      }
    }

    // If not a 401 error or other criteria are not met, reject the error
    return Promise.reject(error);
  }
);

export default axiosConfig;
