// src/reducers/textDocumentReducers.js
import {
    TEXT_DOCUMENT_LIST_REQUEST,
    TEXT_DOCUMENT_LIST_SUCCESS,
    TEXT_DOCUMENT_LIST_FAIL,
  } from '../constants/textDocumentConstants';
  
  // Reducer for handling the state of text document list
  export const textDocumentListReducer = (state = { textDocuments: [] }, action) => {
    switch (action.type) {
      case TEXT_DOCUMENT_LIST_REQUEST:
        return { loading: true, textDocuments: [] }; // While loading, reset textDocuments to an empty array
  
      case TEXT_DOCUMENT_LIST_SUCCESS:
        return { loading: false, textDocuments: action.payload }; // On success, set loading to false and update textDocuments with payload
  
      case TEXT_DOCUMENT_LIST_FAIL:
        return { loading: false, error: action.payload }; // On failure, set loading to false and capture the error message
  
      default:
        return state; // Default case returns the current state unchanged
    }
  };
  