import {
  ADMIN_COURSE_LIST_REQUEST,
  ADMIN_COURSE_LIST_SUCCESS,
  ADMIN_COURSE_LIST_FAIL,
  ADMIN_COURSE_DETAILS_REQUEST,
  ADMIN_COURSE_DETAILS_SUCCESS,
  ADMIN_COURSE_DETAILS_FAIL,
  ADMIN_COURSE_CREATE_REQUEST,
  ADMIN_COURSE_CREATE_SUCCESS,
  ADMIN_COURSE_CREATE_FAIL,
  ADMIN_COURSE_UPDATE_REQUEST,
  ADMIN_COURSE_UPDATE_SUCCESS,
  ADMIN_COURSE_UPDATE_FAIL,
  ADMIN_COURSE_DELETE_REQUEST,
  ADMIN_COURSE_DELETE_SUCCESS,
  ADMIN_COURSE_DELETE_FAIL,
} from '../constants/adminCourseConstants';

export const adminCourseListReducer = (state = { courses: [] }, action) => {
  switch (action.type) {
    case ADMIN_COURSE_LIST_REQUEST:
      return { loading: true, courses: [] };
    case ADMIN_COURSE_LIST_SUCCESS:
      return { loading: false, courses: action.payload };
    case ADMIN_COURSE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminCourseDetailsReducer = (state = { course: {} }, action) => {
  switch (action.type) {
    case ADMIN_COURSE_DETAILS_REQUEST:
      return { loading: true, ...state };
    case ADMIN_COURSE_DETAILS_SUCCESS:
      return { loading: false, course: action.payload };
    case ADMIN_COURSE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminCourseCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_COURSE_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_COURSE_CREATE_SUCCESS:
      return { loading: false, success: true, course: action.payload };
    case ADMIN_COURSE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminCourseUpdateReducer = (state = { course: {} }, action) => {
  switch (action.type) {
    case ADMIN_COURSE_UPDATE_REQUEST:
      return { loading: true };
    case ADMIN_COURSE_UPDATE_SUCCESS:
      return { loading: false, success: true, course: action.payload };
    case ADMIN_COURSE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminCourseDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_COURSE_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_COURSE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_COURSE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
