// src/App.js
import React, { Suspense, lazy } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import { AdminProtectedRoute } from './components/ProtectedRoute';
import './styles.css';

const HomeScreen = lazy(() => import('./screens/HomeScreen'));
const ProductScreen = lazy(() => import('./screens/ProductScreen'));
const DocumentViewerScreen = lazy(() => import('./screens/DocumentViewerScreen'));
const SubtopicDetailsScreen = lazy(() => import('./screens/SubtopicDetailsScreen'));
const LoginScreen = lazy(() => import('./screens/LoginScreen'));
const RegisterScreen = lazy(() => import('./screens/RegisterScreen'));
const UserProfileScreen = lazy(() => import('./screens/UserProfileScreen'));
const ForgotPasswordScreen = lazy(() => import('./screens/ForgotPasswordScreen'));
const ResetPasswordScreen = lazy(() => import('./screens/ResetPasswordScreen'));
const SubscriptionPage = lazy(() => import('./screens/SubscriptionPage'));
const SubscriptionDetailsScreen = lazy(() => import('./screens/SubscriptionDetailsScreen'));
const HelpDeskScreen = lazy(() => import('./screens/HelpDeskScreen'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
// other imports...

const AdminUserListScreen = lazy(() => import('./screens/AdminUserListScreen'));
const AdminUserEditScreen = lazy(() => import('./screens/AdminUserEditScreen'));
const AdminUserCreateScreen = lazy(() => import('./screens/AdminUserCreateScreen'));
const AdminCourseListScreen = lazy(() => import('./screens/AdminCourseListScreen'));
const AdminCourseEditScreen = lazy(() => import('./screens/AdminCourseEditScreen'));
const AdminCourseCreateScreen = lazy(() => import('./screens/AdminCourseCreateScreen'));

const AdminChapterListScreen = lazy(() => import('./screens/AdminChapterListScreen'));
const AdminChapterEditScreen = lazy(() => import('./screens/AdminChapterEditScreen'));
const AdminChapterCreateScreen = lazy(() => import('./screens/AdminChapterCreateScreen'));

const AdminSubchapterListScreen = lazy(() => import('./screens/AdminSubchapterListScreen'));
const AdminSubchapterEditScreen = lazy(() => import('./screens/AdminSubchapterEditScreen'));
const AdminSubchapterCreateScreen = lazy(() => import('./screens/AdminSubchapterCreateScreen'));

const AdminSubtopicListScreen = lazy(() => import('./screens/AdminSubtopicListScreen'));
const AdminSubtopicEditScreen = lazy(() => import('./screens/AdminSubtopicEditScreen'));
const AdminSubtopicCreateScreen = lazy(() => import('./screens/AdminSubtopicCreateScreen'));

const AdminTextDocumentListScreen = lazy(() => import('./screens/AdminTextDocumentListScreen'));
const AdminTextDocumentEditScreen = lazy(() => import('./screens/AdminTextDocumentEditScreen'));
const AdminTextDocumentCreateScreen = lazy(() => import('./screens/AdminTextDocumentCreateScreen'));

const AdminReviewListScreen = lazy(() => import('./screens/AdminReviewListScreen'));

const AdminInstructorListScreen = lazy(() => import('./screens/AdminInstructorListScreen'));
const AdminInstructorEditScreen = lazy(() => import('./screens/AdminInstructorEditScreen'));
const AdminInstructorCreateScreen = lazy(() => import('./screens/AdminInstructorCreateScreen'));

const AdminQuestionListScreen = lazy(() => import('./screens/AdminQuestionListScreen'));
const AdminQuestionEditScreen = lazy(() => import('./screens/AdminQuestionEditScreen'));
const AdminQuestionCreateScreen = lazy(() => import('./screens/AdminQuestionCreateScreen'));

const AdminSubscriptionListScreen = lazy(() => import('./screens/AdminSubscriptionListScreen'));
const AdminSubscriptionEditScreen = lazy(() => import('./screens/AdminSubscriptionEditScreen'));
const AdminSubscriptionCreateScreen = lazy(() => import('./screens/AdminSubscriptionCreateScreen'));

function App() {
  return (
    <>
      <Header />
      <main className="py-3">
        <Container>
          <ErrorBoundary>
            <Suspense fallback={
              <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            }>
              <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/home" element={<HomeScreen />} />
                <Route path="/login" element={<LoginScreen />} />
                <Route path="/register" element={<RegisterScreen />} />
                <Route path="/profile" element={<UserProfileScreen />} />
                <Route path="/courses/:course_id" element={<ProductScreen />} />
                <Route path="/document/:document_id/view" element={<DocumentViewerScreen />} />
                <Route path="/subchapters/:subchapter_id/subtopics" element={<SubtopicDetailsScreen />} />
                <Route path="/password-reset" element={<ForgotPasswordScreen />} />
                <Route path="/password-reset-confirm/:uidb64/:token" element={<ResetPasswordScreen />} />
                <Route path="/courses/:course_id/subscribe" element={<SubscriptionPage />} />
                <Route path="/subscription-details/:course_id" element={<SubscriptionDetailsScreen />} />
                <Route path="/helpdesk" element={<HelpDeskScreen />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                {/* Admin Routes */}
                <Route path="/admin/users" element={<AdminProtectedRoute element={<AdminUserListScreen />} />} />
                <Route path="/admin/user/create" element={<AdminProtectedRoute element={<AdminUserCreateScreen />} />} />
                <Route path="/admin/user/:id/edit" element={<AdminProtectedRoute element={<AdminUserEditScreen />} />} />

                <Route path="/admin/courses" element={<AdminProtectedRoute element={<AdminCourseListScreen />} />} />
                <Route path="/admin/course/create" element={<AdminProtectedRoute element={<AdminCourseCreateScreen />} />} />
                <Route path="/admin/course/:id/edit" element={<AdminProtectedRoute element={<AdminCourseEditScreen />} />} />

                <Route path="/admin/chapters" element={<AdminProtectedRoute element={<AdminChapterListScreen />} />} />
                <Route path="/admin/chapter/create" element={<AdminProtectedRoute element={<AdminChapterCreateScreen />} />} />
                <Route path="/admin/chapter/:id/edit" element={<AdminProtectedRoute element={<AdminChapterEditScreen />} />} />

                <Route path="/admin/subchapters" element={<AdminProtectedRoute element={<AdminSubchapterListScreen />} />} />
                <Route path="/admin/subchapter/create" element={<AdminProtectedRoute element={<AdminSubchapterCreateScreen />} />} />
                <Route path="/admin/subchapter/:id/edit" element={<AdminProtectedRoute element={<AdminSubchapterEditScreen />} />} />

                <Route path="/admin/subtopics" element={<AdminProtectedRoute element={<AdminSubtopicListScreen />} />} />
                <Route path="/admin/subtopic/create" element={<AdminProtectedRoute element={<AdminSubtopicCreateScreen />} />} />
                <Route path="/admin/subtopic/:id/edit" element={<AdminProtectedRoute element={<AdminSubtopicEditScreen />} />} />

                <Route path="/admin/textdocuments" element={<AdminProtectedRoute element={<AdminTextDocumentListScreen />} />} />
                <Route path="/admin/textdocument/create" element={<AdminProtectedRoute element={<AdminTextDocumentCreateScreen />} />} />
                <Route path="/admin/textdocument/:id/edit" element={<AdminProtectedRoute element={<AdminTextDocumentEditScreen />} />} />

                <Route path="/admin/reviews" element={<AdminProtectedRoute element={<AdminReviewListScreen />} />} />

                <Route path="/admin/instructors" element={<AdminProtectedRoute element={<AdminInstructorListScreen />} />} />
                <Route path="/admin/instructor/create" element={<AdminProtectedRoute element={<AdminInstructorCreateScreen />} />} />
                <Route path="/admin/instructor/:id/edit" element={<AdminProtectedRoute element={<AdminInstructorEditScreen />} />} />

                <Route path="/admin/questions" element={<AdminProtectedRoute element={<AdminQuestionListScreen />} />} />
                <Route path="/admin/question/create" element={<AdminProtectedRoute element={<AdminQuestionCreateScreen />} />} />
                <Route path="/admin/question/:id/edit" element={<AdminProtectedRoute element={<AdminQuestionEditScreen />} />} />

                <Route path="/admin/subscriptions" element={<AdminProtectedRoute element={<AdminSubscriptionListScreen />} />} />
                <Route path="/admin/subscription/create" element={<AdminProtectedRoute element={<AdminSubscriptionCreateScreen />} />} />
                <Route path="/admin/subscription/:id/edit" element={<AdminProtectedRoute element={<AdminSubscriptionEditScreen />} />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </Container>
      </main>
      <Footer />
    </>
  );
}

export default App;
