// src/reducers/questionReducers.js
import {
  QUESTION_LIST_REQUEST,
  QUESTION_LIST_SUCCESS,
  QUESTION_LIST_FAIL,
  QUESTION_POST_REQUEST,
  QUESTION_POST_SUCCESS,
  QUESTION_POST_FAIL,
  ANSWER_POST_REQUEST,
  ANSWER_POST_SUCCESS,
  ANSWER_POST_FAIL,
} from '../constants/questionConstants';

// Reducer for handling the state of question lists
export const questionListReducer = (state = { questions: [] }, action) => {
  switch (action.type) {
    case QUESTION_LIST_REQUEST:
      return { loading: true, questions: [] }; // Start fetching questions
    case QUESTION_LIST_SUCCESS:
      return { loading: false, questions: action.payload }; // Fetching successful
    case QUESTION_LIST_FAIL:
      return { loading: false, error: action.payload }; // Fetching failed
    default:
      return state;
  }
};

// Reducer for handling the state of posting a question
export const questionPostReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTION_POST_REQUEST:
      return { loading: true }; // Start posting a question
    case QUESTION_POST_SUCCESS:
      return { loading: false, success: true }; // Posting successful
    case QUESTION_POST_FAIL:
      return { loading: false, error: action.payload }; // Posting failed
    default:
      return state;
  }
};

// Reducer for handling the state of posting an answer
export const answerPostReducer = (state = {}, action) => {
  switch (action.type) {
    case ANSWER_POST_REQUEST:
      return { loading: true }; // Start posting an answer
    case ANSWER_POST_SUCCESS:
      return { loading: false, success: true }; // Posting successful
    case ANSWER_POST_FAIL:
      return { loading: false, error: action.payload }; // Posting failed
    default:
      return state;
  }
};
