// src/reducers/subtopicReducers.js
import {
    SUBTOPIC_LIST_REQUEST,
    SUBTOPIC_LIST_SUCCESS,
    SUBTOPIC_LIST_FAIL
} from '../constants/subtopicConstants';

// Reducer for handling the state of subtopic list
const subtopicListReducer = (state = { subtopics: [] }, action) => {
    switch(action.type){
        case SUBTOPIC_LIST_REQUEST:
            return { loading: true, subtopics: [] }; // When the request is sent, loading is true and subtopics array is empty

        case SUBTOPIC_LIST_SUCCESS:
            return { loading: false, subtopics: action.payload }; // When data is successfully fetched, loading is false and subtopics array is populated

        case SUBTOPIC_LIST_FAIL:
            return { loading: false, error: action.payload }; // When fetching fails, loading is false and error is stored

        default:
            return state; // Default case to return the current state
    }
}

export default subtopicListReducer;
