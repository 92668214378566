import {
  ADMIN_SUBCHAPTER_LIST_REQUEST,
  ADMIN_SUBCHAPTER_LIST_SUCCESS,
  ADMIN_SUBCHAPTER_LIST_FAIL,
  ADMIN_SUBCHAPTER_DETAILS_REQUEST,
  ADMIN_SUBCHAPTER_DETAILS_SUCCESS,
  ADMIN_SUBCHAPTER_DETAILS_FAIL,
  ADMIN_SUBCHAPTER_CREATE_REQUEST,
  ADMIN_SUBCHAPTER_CREATE_SUCCESS,
  ADMIN_SUBCHAPTER_CREATE_FAIL,
  ADMIN_SUBCHAPTER_UPDATE_REQUEST,
  ADMIN_SUBCHAPTER_UPDATE_SUCCESS,
  ADMIN_SUBCHAPTER_UPDATE_FAIL,
  ADMIN_SUBCHAPTER_DELETE_REQUEST,
  ADMIN_SUBCHAPTER_DELETE_SUCCESS,
  ADMIN_SUBCHAPTER_DELETE_FAIL,
} from '../constants/adminSubchapterConstants';

export const adminSubchapterListReducer = (state = { subchapters: [] }, action) => {
  switch (action.type) {
    case ADMIN_SUBCHAPTER_LIST_REQUEST:
      return { loading: true, subchapters: [] };
    case ADMIN_SUBCHAPTER_LIST_SUCCESS:
      return { loading: false, subchapters: action.payload };
    case ADMIN_SUBCHAPTER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminSubchapterDetailsReducer = (state = { subchapter: {} }, action) => {
  switch (action.type) {
    case ADMIN_SUBCHAPTER_DETAILS_REQUEST:
      return { loading: true, ...state };
    case ADMIN_SUBCHAPTER_DETAILS_SUCCESS:
      return { loading: false, subchapter: action.payload };
    case ADMIN_SUBCHAPTER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminSubchapterCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_SUBCHAPTER_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_SUBCHAPTER_CREATE_SUCCESS:
      return { loading: false, success: true, subchapter: action.payload };
    case ADMIN_SUBCHAPTER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminSubchapterUpdateReducer = (state = { subchapter: {} }, action) => {
  switch (action.type) {
    case ADMIN_SUBCHAPTER_UPDATE_REQUEST:
      return { loading: true };
    case ADMIN_SUBCHAPTER_UPDATE_SUCCESS:
      return { loading: false, success: true, subchapter: action.payload };
    case ADMIN_SUBCHAPTER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminSubchapterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_SUBCHAPTER_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_SUBCHAPTER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_SUBCHAPTER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
