import {
  ADMIN_QUESTION_LIST_REQUEST,
  ADMIN_QUESTION_LIST_SUCCESS,
  ADMIN_QUESTION_LIST_FAIL,
  ADMIN_QUESTION_DETAILS_REQUEST,
  ADMIN_QUESTION_DETAILS_SUCCESS,
  ADMIN_QUESTION_DETAILS_FAIL,
  ADMIN_QUESTION_CREATE_REQUEST,
  ADMIN_QUESTION_CREATE_SUCCESS,
  ADMIN_QUESTION_CREATE_FAIL,
  ADMIN_QUESTION_CREATE_RESET,
  ADMIN_QUESTION_UPDATE_REQUEST,
  ADMIN_QUESTION_UPDATE_SUCCESS,
  ADMIN_QUESTION_UPDATE_FAIL,
  ADMIN_QUESTION_UPDATE_RESET,
  ADMIN_QUESTION_DELETE_REQUEST,
  ADMIN_QUESTION_DELETE_SUCCESS,
  ADMIN_QUESTION_DELETE_FAIL,
} from '../constants/adminQuestionConstants';

export const adminQuestionListReducer = (state = { questions: [] }, action) => {
  switch (action.type) {
    case ADMIN_QUESTION_LIST_REQUEST:
      return { loading: true, questions: [] };
    case ADMIN_QUESTION_LIST_SUCCESS:
      return { loading: false, questions: action.payload };
    case ADMIN_QUESTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminQuestionDetailsReducer = (state = { question: {} }, action) => {
  switch (action.type) {
    case ADMIN_QUESTION_DETAILS_REQUEST:
      return { loading: true, ...state };
    case ADMIN_QUESTION_DETAILS_SUCCESS:
      return { loading: false, question: action.payload };
    case ADMIN_QUESTION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminQuestionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_QUESTION_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_QUESTION_CREATE_SUCCESS:
      return { loading: false, success: true, question: action.payload };
    case ADMIN_QUESTION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_QUESTION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const adminQuestionUpdateReducer = (state = { question: {} }, action) => {
  switch (action.type) {
    case ADMIN_QUESTION_UPDATE_REQUEST:
      return { loading: true };
    case ADMIN_QUESTION_UPDATE_SUCCESS:
      return { loading: false, success: true, question: action.payload };
    case ADMIN_QUESTION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_QUESTION_UPDATE_RESET:
      return { question: {} };
    default:
      return state;
  }
};

export const adminQuestionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_QUESTION_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_QUESTION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_QUESTION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
