import {
    ADMIN_REVIEW_LIST_REQUEST,
    ADMIN_REVIEW_LIST_SUCCESS,
    ADMIN_REVIEW_LIST_FAIL,
    ADMIN_REVIEW_DELETE_REQUEST,
    ADMIN_REVIEW_DELETE_SUCCESS,
    ADMIN_REVIEW_DELETE_FAIL,
  } from '../constants/adminReviewConstants';
  
  export const adminReviewListReducer = (state = { reviews: [] }, action) => {
    switch (action.type) {
      case ADMIN_REVIEW_LIST_REQUEST:
        return { loading: true, reviews: [] };
      case ADMIN_REVIEW_LIST_SUCCESS:
        return { loading: false, reviews: action.payload };
      case ADMIN_REVIEW_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const adminReviewDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case ADMIN_REVIEW_DELETE_REQUEST:
        return { loading: true };
      case ADMIN_REVIEW_DELETE_SUCCESS:
        return { loading: false, success: true };
      case ADMIN_REVIEW_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  