import {
  ADMIN_USER_LIST_REQUEST,
  ADMIN_USER_LIST_SUCCESS,
  ADMIN_USER_LIST_FAIL,
  ADMIN_USER_DETAILS_REQUEST,
  ADMIN_USER_DETAILS_SUCCESS,
  ADMIN_USER_DETAILS_FAIL,
  ADMIN_USER_CREATE_REQUEST,
  ADMIN_USER_CREATE_SUCCESS,
  ADMIN_USER_CREATE_FAIL,
  ADMIN_USER_CREATE_RESET,
  ADMIN_USER_UPDATE_REQUEST,
  ADMIN_USER_UPDATE_SUCCESS,
  ADMIN_USER_UPDATE_FAIL,
  ADMIN_USER_UPDATE_RESET,
  ADMIN_USER_DELETE_REQUEST,
  ADMIN_USER_DELETE_SUCCESS,
  ADMIN_USER_DELETE_FAIL,
  ADMIN_ADMIN_USER_LIST_REQUEST,
  ADMIN_ADMIN_USER_LIST_SUCCESS,
  ADMIN_ADMIN_USER_LIST_FAIL,
} from '../constants/adminUserConstants';

export const adminUserListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case ADMIN_USER_LIST_REQUEST:
      return { loading: true, users: [] };
    case ADMIN_USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case ADMIN_USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminUserDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case ADMIN_USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ADMIN_USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case ADMIN_USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminUserCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_USER_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_USER_CREATE_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case ADMIN_USER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_USER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const adminUserUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case ADMIN_USER_UPDATE_REQUEST:
      return { loading: true };
    case ADMIN_USER_UPDATE_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case ADMIN_USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_USER_UPDATE_RESET:
      return { user: {} };
    default:
      return state;
  }
};

export const adminUserDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_USER_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminAdminUserListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case ADMIN_ADMIN_USER_LIST_REQUEST:
      return { loading: true, users: [] };
    case ADMIN_ADMIN_USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case ADMIN_ADMIN_USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
