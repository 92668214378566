// src/constants/subscriptionConstants.js

export const SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAIL = 'SUBSCRIBE_FAIL';

export const CHECK_SUBSCRIPTION_REQUEST = 'CHECK_SUBSCRIPTION_REQUEST';
export const CHECK_SUBSCRIPTION_SUCCESS = 'CHECK_SUBSCRIPTION_SUCCESS';
export const CHECK_SUBSCRIPTION_FAIL = 'CHECK_SUBSCRIPTION_FAIL';

export const GET_SUBSCRIPTION_DETAILS_REQUEST = 'GET_SUBSCRIPTION_DETAILS_REQUEST';
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = 'GET_SUBSCRIPTION_DETAILS_SUCCESS';
export const GET_SUBSCRIPTION_DETAILS_FAIL = 'GET_SUBSCRIPTION_DETAILS_FAIL';

export const EXTEND_SUBSCRIPTION_REQUEST = 'EXTEND_SUBSCRIPTION_REQUEST';
export const EXTEND_SUBSCRIPTION_SUCCESS = 'EXTEND_SUBSCRIPTION_SUCCESS';
export const EXTEND_SUBSCRIPTION_FAIL = 'EXTEND_SUBSCRIPTION_FAIL';

// Razorpay order creation constants
export const CREATE_RAZORPAY_ORDER_REQUEST = 'CREATE_RAZORPAY_ORDER_REQUEST';
export const CREATE_RAZORPAY_ORDER_SUCCESS = 'CREATE_RAZORPAY_ORDER_SUCCESS';
export const CREATE_RAZORPAY_ORDER_FAIL = 'CREATE_RAZORPAY_ORDER_FAIL';

// Payment verification constants
export const VERIFY_PAYMENT_REQUEST = 'VERIFY_PAYMENT_REQUEST';
export const VERIFY_PAYMENT_SUCCESS = 'VERIFY_PAYMENT_SUCCESS';
export const VERIFY_PAYMENT_FAIL = 'VERIFY_PAYMENT_FAIL';
