import {
  ADMIN_TEXTDOCUMENT_LIST_REQUEST,
  ADMIN_TEXTDOCUMENT_LIST_SUCCESS,
  ADMIN_TEXTDOCUMENT_LIST_FAIL,
  ADMIN_TEXTDOCUMENT_DETAILS_REQUEST,
  ADMIN_TEXTDOCUMENT_DETAILS_SUCCESS,
  ADMIN_TEXTDOCUMENT_DETAILS_FAIL,
  ADMIN_TEXTDOCUMENT_CREATE_REQUEST,
  ADMIN_TEXTDOCUMENT_CREATE_SUCCESS,
  ADMIN_TEXTDOCUMENT_CREATE_FAIL,
  ADMIN_TEXTDOCUMENT_UPDATE_REQUEST,
  ADMIN_TEXTDOCUMENT_UPDATE_SUCCESS,
  ADMIN_TEXTDOCUMENT_UPDATE_FAIL,
  ADMIN_TEXTDOCUMENT_DELETE_REQUEST,
  ADMIN_TEXTDOCUMENT_DELETE_SUCCESS,
  ADMIN_TEXTDOCUMENT_DELETE_FAIL,
} from '../constants/adminTextDocumentConstants';

export const adminTextDocumentListReducer = (state = { textdocuments: [] }, action) => {
  switch (action.type) {
    case ADMIN_TEXTDOCUMENT_LIST_REQUEST:
      return { loading: true, textdocuments: [] };
    case ADMIN_TEXTDOCUMENT_LIST_SUCCESS:
      return { loading: false, textdocuments: action.payload };
    case ADMIN_TEXTDOCUMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminTextDocumentDetailsReducer = (state = { textdocument: {} }, action) => {
  switch (action.type) {
    case ADMIN_TEXTDOCUMENT_DETAILS_REQUEST:
      return { loading: true, ...state };
    case ADMIN_TEXTDOCUMENT_DETAILS_SUCCESS:
      return { loading: false, textdocument: action.payload };
    case ADMIN_TEXTDOCUMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminTextDocumentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_TEXTDOCUMENT_CREATE_REQUEST:
      return { loading: true };
    case ADMIN_TEXTDOCUMENT_CREATE_SUCCESS:
      return { loading: false, success: true, textdocument: action.payload };
    case ADMIN_TEXTDOCUMENT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminTextDocumentUpdateReducer = (state = { textdocument: {} }, action) => {
  switch (action.type) {
    case ADMIN_TEXTDOCUMENT_UPDATE_REQUEST:
      return { loading: true };
    case ADMIN_TEXTDOCUMENT_UPDATE_SUCCESS:
      return { loading: false, success: true, textdocument: action.payload };
    case ADMIN_TEXTDOCUMENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminTextDocumentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_TEXTDOCUMENT_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_TEXTDOCUMENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_TEXTDOCUMENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
