// src/reducers/subscriptionReducers.js

import {
  SUBSCRIBE_REQUEST,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAIL,
  CHECK_SUBSCRIPTION_REQUEST,
  CHECK_SUBSCRIPTION_SUCCESS,
  CHECK_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_DETAILS_REQUEST,
  GET_SUBSCRIPTION_DETAILS_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_FAIL,
  EXTEND_SUBSCRIPTION_REQUEST,
  EXTEND_SUBSCRIPTION_SUCCESS,
  EXTEND_SUBSCRIPTION_FAIL,
  CREATE_RAZORPAY_ORDER_REQUEST,
  CREATE_RAZORPAY_ORDER_SUCCESS,
  CREATE_RAZORPAY_ORDER_FAIL,
  VERIFY_PAYMENT_REQUEST,
  VERIFY_PAYMENT_SUCCESS,
  VERIFY_PAYMENT_FAIL,
} from '../constants/subscriptionConstants';

// Reducer to handle subscription state
export const subscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIBE_REQUEST:
      return { loading: true }; // Subscription request started
    case SUBSCRIBE_SUCCESS:
      return { loading: false, success: true, subscription: action.payload }; // Subscription successful
    case SUBSCRIBE_FAIL:
      return { loading: false, error: action.payload }; // Subscription failed
    default:
      return state;
  }
};

// Reducer to handle check subscription state
export const checkSubscriptionReducer = (state = { isSubscribed: false }, action) => {
  switch (action.type) {
    case CHECK_SUBSCRIPTION_REQUEST:
      return { loading: true }; // Checking subscription request started
    case CHECK_SUBSCRIPTION_SUCCESS:
      return { loading: false, isSubscribed: action.payload.subscribed }; // Check successful
    case CHECK_SUBSCRIPTION_FAIL:
      return { loading: false, error: action.payload }; // Check failed
    default:
      return state;
  }
};

// Reducer to handle subscription details state
export const subscriptionDetailsReducer = (state = { subscription: {} }, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_DETAILS_REQUEST:
      return { loading: true, ...state }; // Fetching subscription details request started
    case GET_SUBSCRIPTION_DETAILS_SUCCESS:
      return { loading: false, subscription: action.payload }; // Fetching subscription details successful
    case GET_SUBSCRIPTION_DETAILS_FAIL:
      return { loading: false, error: action.payload }; // Fetching subscription details failed
    default:
      return state;
  }
};

// Reducer to handle extend subscription state
export const extendSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case EXTEND_SUBSCRIPTION_REQUEST:
      return { loading: true }; // Extending subscription request started
    case EXTEND_SUBSCRIPTION_SUCCESS:
      return { loading: false, success: true, subscription: action.payload }; // Extending subscription successful
    case EXTEND_SUBSCRIPTION_FAIL:
      return { loading: false, error: action.payload }; // Extending subscription failed
    default:
      return state;
  }
};

// Reducer to handle Razorpay order creation state
export const createRazorpayOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_RAZORPAY_ORDER_REQUEST:
      return { loading: true }; // Razorpay order creation request started
    case CREATE_RAZORPAY_ORDER_SUCCESS:
      return { loading: false, success: true, orderDetails: action.payload }; // Razorpay order creation successful
    case CREATE_RAZORPAY_ORDER_FAIL:
      return { loading: false, error: action.payload }; // Razorpay order creation failed
    default:
      return state;
  }
};

// Reducer to handle payment verification state
export const verifyPaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_PAYMENT_REQUEST:
      return { loading: true }; // Payment verification request started
    case VERIFY_PAYMENT_SUCCESS:
      return { loading: false, success: true, paymentResult: action.payload }; // Payment verification successful
    case VERIFY_PAYMENT_FAIL:
      return { loading: false, error: action.payload }; // Payment verification failed
    default:
      return state;
  }
};
