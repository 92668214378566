// src/components/ErrorBoundary.js
import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state to render the fallback UI
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Capture error information
    this.setState({ errorInfo });

    // Log error to an external service
    console.error("Logging error to monitoring service:", error, errorInfo);
    // Example: logErrorToMyService(error, errorInfo);
  }

  handleReload = () => {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      // Render custom fallback UI
      return (
        <Alert variant="danger" className="mt-3">
          <Alert.Heading>Oops! Something went wrong.</Alert.Heading>
          <p>We encountered an unexpected error. Please try refreshing the page or contact support if the problem persists.</p>
          {process.env.NODE_ENV === 'development' && (
            <>
              <hr />
              <p>{this.state.error && this.state.error.toString()}</p>
              <pre>{this.state.errorInfo && this.state.errorInfo.componentStack}</pre>
            </>
          )}
          <Button onClick={this.handleReload} variant="primary" className="mt-3">
            Try Again
          </Button>
        </Alert>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
