// src/constants/adminChapterConstants.js
export const ADMIN_CHAPTER_LIST_REQUEST = 'ADMIN_CHAPTER_LIST_REQUEST';
export const ADMIN_CHAPTER_LIST_SUCCESS = 'ADMIN_CHAPTER_LIST_SUCCESS';
export const ADMIN_CHAPTER_LIST_FAIL = 'ADMIN_CHAPTER_LIST_FAIL';

export const ADMIN_CHAPTER_DETAILS_REQUEST = 'ADMIN_CHAPTER_DETAILS_REQUEST';
export const ADMIN_CHAPTER_DETAILS_SUCCESS = 'ADMIN_CHAPTER_DETAILS_SUCCESS';
export const ADMIN_CHAPTER_DETAILS_FAIL = 'ADMIN_CHAPTER_DETAILS_FAIL';

export const ADMIN_CHAPTER_CREATE_REQUEST = 'ADMIN_CHAPTER_CREATE_REQUEST';
export const ADMIN_CHAPTER_CREATE_SUCCESS = 'ADMIN_CHAPTER_CREATE_SUCCESS';
export const ADMIN_CHAPTER_CREATE_FAIL = 'ADMIN_CHAPTER_CREATE_FAIL';

export const ADMIN_CHAPTER_UPDATE_REQUEST = 'ADMIN_CHAPTER_UPDATE_REQUEST';
export const ADMIN_CHAPTER_UPDATE_SUCCESS = 'ADMIN_CHAPTER_UPDATE_SUCCESS';
export const ADMIN_CHAPTER_UPDATE_FAIL = 'ADMIN_CHAPTER_UPDATE_FAIL';
export const ADMIN_CHAPTER_UPDATE_RESET = 'ADMIN_CHAPTER_UPDATE_RESET';

export const ADMIN_CHAPTER_DELETE_REQUEST = 'ADMIN_CHAPTER_DELETE_REQUEST';
export const ADMIN_CHAPTER_DELETE_SUCCESS = 'ADMIN_CHAPTER_DELETE_SUCCESS';
export const ADMIN_CHAPTER_DELETE_FAIL = 'ADMIN_CHAPTER_DELETE_FAIL';
