export const QUESTION_LIST_REQUEST = 'QUESTION_LIST_REQUEST';
export const QUESTION_LIST_SUCCESS = 'QUESTION_LIST_SUCCESS';
export const QUESTION_LIST_FAIL = 'QUESTION_LIST_FAIL';

export const QUESTION_POST_REQUEST = 'QUESTION_POST_REQUEST';
export const QUESTION_POST_SUCCESS = 'QUESTION_POST_SUCCESS';
export const QUESTION_POST_FAIL = 'QUESTION_POST_FAIL';

export const ANSWER_POST_REQUEST = 'ANSWER_POST_REQUEST';
export const ANSWER_POST_SUCCESS = 'ANSWER_POST_SUCCESS';
export const ANSWER_POST_FAIL = 'ANSWER_POST_FAIL';
