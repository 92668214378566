// src/reducers/reviewReducers.js

import {
    REVIEW_LIST_REQUEST,
    REVIEW_LIST_SUCCESS,
    REVIEW_LIST_FAIL,
    REVIEW_CREATE_REQUEST,
    REVIEW_CREATE_SUCCESS,
    REVIEW_CREATE_FAIL,
    REVIEW_CREATE_RESET,
  } from '../constants/reviewConstants';
  
  // Reducer for handling the state of review lists
  export const reviewListReducer = (state = { reviews: [] }, action) => {
    switch (action.type) {
      case REVIEW_LIST_REQUEST:
        return { loading: true, reviews: [] }; // Start fetching reviews
      case REVIEW_LIST_SUCCESS:
        return { loading: false, reviews: action.payload }; // Fetching successful
      case REVIEW_LIST_FAIL:
        return { loading: false, error: action.payload }; // Fetching failed
      default:
        return state;
    }
  };
  
  // Reducer for handling the state of creating a review
  export const reviewCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case REVIEW_CREATE_REQUEST:
        return { loading: true }; // Start creating a review
      case REVIEW_CREATE_SUCCESS:
        return { loading: false, success: true }; // Creation successful
      case REVIEW_CREATE_FAIL:
        return { loading: false, error: action.payload }; // Creation failed
      case REVIEW_CREATE_RESET:
        return {}; // Reset the state
      default:
        return state;
    }
  };
  